import * as React from "react";
import { Container, Header, Segment, Image, Item, Icon, ItemGroup } from "semantic-ui-react";
import Title from "../components/Title";
import { withLayout } from "../components/Layout";
import QAOthersList from "../components/CybozuQAOthersList"; 
import CybozuQAList from "../components/CybozuQAList";
import CybozuA11YList from "../components/CybozuA11YList";

const pageTitle = "Cybozu QAの情報まとめ";

const AboutPage: React.FC = () => {
  return (
    <>
    <Container className="bg-sabo">
      {/* Header */}
      <Title title={pageTitle} />
      
      <Segment vertical padded="very">
          <Item.Group className="cy-qa" link divided>
            <CybozuQAList />
          </Item.Group>
          </Segment>
          {/* others */}
          <Header as='h3'>その他の情報</Header>
          <p>CybozuTech（このサイト） や CybozuInsideOutのX（旧 Twitter）を、ご覧ください！</p>
          <Segment vertical padded="very">
          <ItemGroup link divided>
            <QAOthersList />
          </ItemGroup>
      </Segment>
          
    </Container>
  </>
  );
};

export default withLayout(AboutPage, pageTitle);
